html,
body {
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.table > :not(caption) > * > * {
  color: #ebdab4;
}

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #242728;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}

a {
  color: #f84b3c !important;
  margin-left: 5px;
  text-decoration: none !important;
}

@media only screen and (max-width: 500px) {
  #wrapper {
    flex-direction: column;
  }
  #editor {
    width: 100vw !important;
    border-right: 1px solid rgba(132, 175, 116, 0.3);
  }
  #output {
    width: 100vw !important;
    border-top: 1px solid rgba(132, 175, 116, 0.3);
    border-right: 1px solid rgba(132, 175, 116, 0.3);
  }
}
